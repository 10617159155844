
$(document).ready(function () {
	$("#systems")
		.tablesorter({
			theme: 'bootstrap',
			widthFixed: true,
			widgets: ['zebra', 'filter', 'pager']
		})
		.appendTablesorterPagerControls({
			sizes: [10, 50, 100],
			initialSize: 10
		});

	try {
		$("#systemsAlarms")
			.tablesorter({
				theme: 'bootstrap',
				widthFixed: true,
				widgets: ['zebra', 'filter', 'pager']
			})
			.appendTablesorterPagerControls({
				sizes: [10, 50, 100],
				initialSize: 10
			});
	}
	catch (e) {
	}

	try {
		$("#systemsAlarmsCritical")
			.tablesorter({
				theme: 'bootstrap',
				widthFixed: true,
				widgets: ['zebra', 'filter', 'pager']
			})
			.appendTablesorterPagerControls({
				sizes: [10, 50, 100],
				initialSize: 10
			});
	}
	catch (e) {
	}

	try {
		$("#systemsAlarmsAll")
			.tablesorter({
				theme: 'bootstrap',
				widthFixed: true,
				widgets: ['zebra', 'filter', 'pager']
			})
			.appendTablesorterPagerControls({
				sizes: [10, 50, 100],
				initialSize: 10
			});
	}
	catch (e) {
	}

	$('.clickable-row').click(function () {
		window.location = $(this).data("href")
	});

	$('.number-input').on('change', function(){
		$(this).val(parseFloat($(this).val()).toFixed(1));
	});



});

